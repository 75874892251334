import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Button, Section, Box, Text, Span, InputGroup, A } from "../../components/Core";

import { device } from "../../utils";

import { rgba } from "polished";

import GlobalContext from "../../context/GlobalContext";

import imgHero from "../../assets/image/jpeg/pub-review.jpg";
import imgAuthor from "../../assets/image/jpeg/l6-author-image.jpg";
import imgReview from "../../assets/image/png/review.png";

/* Add some form handling functions */
import { navigate } from 'gatsby-link'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}
/* Add some form handling functions end */


const SectionStyled = styled(Section)`
  padding-bottom: 100px;
  padding-top: 0;
  margin-top:3rem;
  
`;

const OfferWrapper = styled(Box)`
  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.ash};
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 50px;
`;

const BtnContainer = styled(Box)`
  margin-left: 45px;
  margin-top: 30px;

  @media ${device.lg} {
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
`;



const Offer = () => {

  {/*form handling code*/}
  const [state, setState] = React.useState({})
  
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }
  
  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    const email = document.getElementById("email1").value
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => window.analytics.identify({
        email: email
      }))
      .then(() => window.analytics.track("Review Download Form Submitted", {
        email: email
    }))
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }
  
    return (
      <>
<SectionStyled bg="light">
      <Container>
      
        <OfferWrapper>
          <div className="d-flex flex-wrap">
            
            <div className="pt-3 pl-5 d-flex flex-wrap">
            <Col lg="9" xl="8">
              <Title variant="card" fontSize="28px" color="light" mb={3}>
                  Download: Independent User Review
              </Title>
              <Title variant="card" fontSize="24px" color="light" mb={3}>
                  Methods for Automated Single Cell Isolation  and Sub‐Cloning of Human Pluripotent Stem Cells
              </Title>
              <Text color="lightShade" fontSize="18px" lineHeight="28px" mb={3}>
              The paper describes the application of three different automated cell isolation and 
              dispensing devices that can enhance the single‐cell cloning process for hPSCs. 
              In combination with optimized cell culture conditions, these devices 
               offer an attractive alternative compared to manual methods. We explore 
               various aspects of each device system and define protocols for their practical application.
              </Text>
              
              
               {/*add some form here*/}
               <form  
                    name="Download_Review_Form" 
                    method="post"
                    action="/thank-you/"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={handleSubmit}
                    >
                    <div className="position-relative">
                    <p hidden>
                      <label>
                        Don’t fill this out: <input name="bot-field" onChange={handleChange} />
                      </label>
                    </p>
                    <input type="hidden" name="form-name" value="Download_Review_Form" /> 
                      <InputGroup
                        icon={<i className="icon icon-email-84" />}
                        placeholder="Work Email Address"
                        border="none"
                        type="email" 
                        name="email"
                        id="email1"
                        onChange={handleChange}
                        css={`
                        
                         @media${device.sm}{
                          padding-right: 250px;
                        }
                        `}
                      />
                      <Button
                        css={`
                        margin-top: 1rem;
                        @media${device.sm}{
                          margin-top: 0;
                          position: absolute;
                          top: 50%;
                          right: 5px;
                          transform: translateY(-50%);
                        }
  
                        `}
                        type="submit"
                      >
                        Download
                      </Button>
                    </div>
                  </form>
            
            </Col>
            <Col
            lg="2"
            xl="3"
            className="d-flex align-items-center justify-content-lg-end mt-4 mt-md-0"
          >
            <img src={imgReview} alt="User_Review_Screenshot" width={250} height={70} className="img-fluid" />
          </Col>
          </div>

          </div>
        </OfferWrapper>
      </Container>
    </SectionStyled>
 
                      
  
      </>
    );
  };
  
  export default Offer;
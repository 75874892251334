import React, { useContext } from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Text, Span, InputGroup, A } from "../../components/Core";

import GlobalContext from "../../context/GlobalContext";

import { device } from "../../utils";

import imgHero from "../../assets/image/jpeg/user-review.jpg";
import imgAuthor from "../../assets/image/jpeg/l6-author-image.jpg";

/* Add some form handling functions */
import { navigate } from 'gatsby-link'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}
/* Add some form handling functions end */

const ImgRight = styled(Box)`
  max-width: 80%;
  margin-left: -35px;
  @media ${device.sm} {
    max-width: unset;
    margin-left: -35px;
    position: relative;
    left: -35px;
  }
  @media ${device.md} {
    margin-left: -85px;
    position: relative;
    left: -35px;
  }
`;

const ULStyled = styled.ul`
  list-style: none;
  margin: 0;
  padding-top: 0;
  padding-left: 0;

  li {
    &:nth-child(odd) {
      @media ${device.sm} {
        margin-right: 40px;
      }
    }
    color: #19191b;
    font-size: 21px;
    font-weight: 500;
    letter-spacing: -0.66px;
    line-height: 50px;
    display: flex;
    margin-bottom: 5px;

    &:before {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      display: inline-block;
      font-size: 13px;
      width: 30px;
      min-width: 30px;
      height: 30px;
      background-color: ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 500px;
      color: ${({ theme }) => theme.colors.secondary};
      position: relative;
      top: 10px;
      margin-right: 13px;
    }
  }
`;

const BoxStyled = styled(Box)`
  border-bottom: 1px solid #eae9f2;
  padding-top: 17px;
  padding-bottom: 45px;

  @media ${device.md} {
    padding-top: 90px;
    padding-bottom: 85px;
  }
`;

const AuthorWidget = styled(Box)`
  display: flex;
  flex-direction: column;

  @media ${device.sm} {
    flex-direction: row;
  }

  @media ${device.lg} {
    padding-left: 90px;
    padding-right: 90px;
  }
`;

const AuthorImage = styled(Box)`
  min-width: 111px;
  max-width: 111px;
  height: 111px;
  border-radius: 500px;
  overflow: hidden;
`;

const AuthorText = styled(Box)`
  padding-top: 20px;

  @media ${device.sm} {
    padding-left: 30px;
    padding-top: 0px;
  }
`;





const Hero = () => {

{/*form handling code*/}
const [state, setState] = React.useState({})

const handleChange = (e) => {
  setState({ ...state, [e.target.name]: e.target.value })
}

const handleSubmit = (e) => {
  e.preventDefault()
  const form = e.target
  fetch('/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: encode({
      'form-name': form.getAttribute('name'),
      ...state,
    }),
  })
    .then(() => navigate(form.getAttribute('action')))
    .catch((error) => alert(error))
}
{/*form handling code end*/}

  {/*form code end*/}
  const gContext = useContext(GlobalContext);

    const openVideoModal = (e) => {
      e.preventDefault();
      gContext.toggleVideoModal();
    };
  {/*form code end*/}

  return (
    <>
      {/* <!-- Hero Area --> */}
      <Section bg="#f6f6f8" className="position-relative" pb="30px !important">
        <div className="pt-5"></div>
        <Container>
          <Row className="align-items-center position-relative">
            <Col
              lg="4"
              xl="5"
              className="position-static"
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-delay="500"
              data-aos-once="true"
            >
              <ImgRight>
                <img src={imgHero} alt="" className="img-fluid" />
              </ImgRight>
            </Col>
            <Col lg="8" xl="7">
              <div
                data-aos="fade-left"
                data-aos-duration="750"
                data-aos-delay="500"
                data-aos-once="true"
              >
                <Box pt={[4, null, null, 0]} pl={5}>
                  <Title mb={10}>
                  Single-Cell Cloning <br className="d-none d-sm-block" />
                  Automation Technologies 
                  </Title>
                  <div className="d-flex flex-column align-items-start pt-2">
                    <Text fontSize={"18px"} color="ash" fontWeight={500}>
                      Learn more about single-cell cloning automation in an independently published user experience summary of emerging single-cell cloning technologies<br />
                    </Text>
                  </div>
                  <Box mb={3} mt={3}>
                    <ULStyled>
                      <li>Proof of monoclonality</li>
                      <li>Easy handling - small size and lightweight</li>
                      <li>Low cost of acquisition and ongoing costs</li>
                    </ULStyled>
                  </Box>
                  <div className="d-flex flex-column align-items-start pt-2">
{/*add some form here*/}
                  <form  
                  name="Download_Review_Form" 
                  method="post"
                  action="/thank-you/"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  onSubmit={handleSubmit}
                  >
                  <div className="position-relative">
                  <p hidden>
                    <label>
                      Don’t fill this out: <input name="bot-field" onChange={handleChange} />
                    </label>
                  </p>
                  <input type="hidden" name="form-name" value="Download_Review_Form" /> 
                    <InputGroup
                      icon={<i className="icon icon-email-84" />}
                      placeholder="Work Email address"
                      border="none"
                      type="email" 
                      name="email"
                      onChange={handleChange}
                      css={`
                      
                       @media${device.sm}{
                        padding-right: 250px;
                      }
                      `}
                    />
                    <Button
                      css={`
                      margin-top: 1rem;
                      @media${device.sm}{
                        margin-top: 0;
                        position: absolute;
                        top: 50%;
                        right: 5px;
                        transform: translateY(-50%);
                      }

                      `}
                      type="submit"
                    >
                      Download
                    </Button>
                  </div>
                </form>
                    <Text fontSize={"18px"} color="ash" fontWeight={500}>
                      Interested in an online demo?{" "}
                      
                      <a onClick={openVideoModal}>
                        <Span color="secondary">Book now</Span>
                      </a>
                    </Text>
                  </div>
                </Box>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>

    </>
  );
};

export default Hero;

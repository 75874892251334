import React from "react";
import Hero from "../sections/isoCellAccessible/Hero";
/*import Hero2 from "../sections/isoCellAccessible/Hero2";*/
import Feature1 from "../sections/isoCellAccessible/Feature1";
/* Add Review here */
import Offer from "../sections/isoCellAccessible/Offer";
import Clients from "../sections/isoCellAccessible/Clients";
import Pricing2 from "../sections/isoCellAccessible/PricingTable2";
import Quote from "../sections/isoCellAccessible/Quote";
import PageWrapper from "../sections/isoCellAccessible/PageWrapper";




const isocellaccessible = () => {

  return (
    <>
      <PageWrapper>
        <Hero />
        <Quote />
        <Clients />
        <Feature1 />
        <Offer />
        <Pricing2 />
      </PageWrapper>
    </>
  );
};
export default isocellaccessible;

import React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";

import { Box, Text } from "../../components/Core";
import { device } from "../../utils";

import imgL1Brand1 from "../../assets/image/svg/AbbVie_logo.svg";
import imgL1Brand2 from "../../assets/image/svg/ooo.svg";
import imgL1Brand3 from "../../assets/image/svg/almac-group-logo-vector.svg";
import imgL1Brand4 from "../../assets/image/svg/almac-group-logo-vector.svg";
import imgL1Brand5 from "../../assets/image/png/l1-brand5.png";

const TitleContainer = styled(Box)`
  position: relative;
  &:after {
    content: "";
    height: 1px;
    position: absolute;
    right: 0;
    top: 50%;
    width: 32%;
    background: ${({ theme }) => theme.colors.border};
    margin-top: 0.5px;
    display: none;

    @media ${device.md} {
      width: 55%;
      display: block;
    }
    @media ${device.lg} {
      width: 68%;
    }
    @media ${device.xl} {
      width: 80%;
    }
  }
`;

const Brand = styled(Box)`
  opacity: 0.7;
  transition: all 0.3s ease-out;
  &:hover {
    opacity: 1;
  }
`;

const Clients = () => (
  <>
    {/* <!-- Clients Brands Area --> */}
    <Box pb={"30px"} bg="#f6f6f8">
      <Container style={{width:"90%",maxWidth:"90%"}}>
        <TitleContainer mb={"20px"}>
          <Text fontSize="18px" as="h4" className="">
            Some of our customers{" "}
          </Text>
        </TitleContainer>
      </Container>
      <Container style={{width:"90%",maxWidth:"90%"}}>
        <Box
          className="d-flex justify-content-center justify-content-lg-between
         align-items-center flex-wrap"
          mx="-32px"
        >
          <Brand className="" py={3} mx={4} style={{textAlign:"center"}}>
            <h4 color="heading">MDC, Germany</h4><br/>
            <h4 color="heading"><b>Core Facilities</b></h4>
          </Brand>
          <Brand className="" py={3} mx={4} style={{textAlign:"center"}}>
            <h4 color="heading">UAMS, USA</h4><br/>
            <h4 color="heading"><b>Academic Labs</b></h4>
          </Brand>
          <Brand className="" py={3} mx={4} style={{textAlign:"center"}}>
            <h4 color="heading">Charit&eacute;, Germany </h4><br/>
            <h4 color="heading"><b>Hospital</b></h4>
          </Brand>
          <Brand className="" py={3} mx={4} style={{textAlign:"center"}}>
            <h4 color="heading">ALMAC Discovery, UK</h4><br/>
            <h4 color="heading"><b>CRO</b></h4>
          </Brand>
          <Brand className="" py={3} mx={4} style={{textAlign:"center"}}>
            <h4 color="heading">Abbvie, USA</h4><br/>
            <h4 color="heading"><b>Pharma</b></h4>
          </Brand>
        </Box>
      </Container>
    </Box>
  </>
);

export default Clients;

import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";

import { Title, Section, Box, Text } from "../../components/Core";
import { breakpoints } from "../../utils";
import { device } from "../../utils";

import imgOfferBook from "../../assets/image/png/current_protocols_inverted.png";
import imgQuoteStart from "../../assets/image/png/quote-shape-start.png";
import imgQuoteEnd from "../../assets/image/png/quote-shape-end.png";

const TitleContainer = styled(Box)`
  position: relative;
  &:after {
    content: "";
    height: 1px;
    position: absolute;
    right: 0;
    top: 50%;
    width: 32%;
    background: ${({ theme }) => theme.colors.border};
    margin-top: 0.5px;
    display: none;

    @media ${device.md} {
      width: 40%;
      display: block;
    }
    @media ${device.lg} {
      width: 52%;
    }
    @media ${device.xl} {
      width: 60%;
    }
  }
`;


const ContentCard = ({
  className,
  image,
  name,
  author,
  company,
  children,
  ...rest
}) => (
  <Box
    bg="light"
    border="1px solid"
    borderColor="border"
    p="30px"
    mx="20px"
    borderRadius={10}
    className={`${className}`}
    {...rest}
    css={`
      &:focus {
        outline: none;
      }
    `}
  >
    <Text color="dark" mb={4}>
      {children}
    </Text>
    <Box className={`d-flex justify-content-between`}>
      <Box className="d-flex justify-content-center align-items-start" mr={3}>
        <img src={image} alt="" className="img-fluid" />
      </Box>
      <div className="flex-grow-1">
        <Title variant="card" mb={0}>
          {name}
        </Title>
        <Text fontSize={'15px'} lineHeight={'24px'}>{company}<br/>{author}</Text>
      </div>
    </Box>
  </Box>
);

const Quote = () => {
    
  return (
    <>
      <Section bg="#F5F5F7" style={{paddingTop:'10px',paddingBottom:'50px'}}>
      <Container>
        <TitleContainer mb={"20px"}>
          <Text fontSize="18px" as="h4" className="">
            Highlight from the paper{" "}
          </Text>
        </TitleContainer>
      </Container>
        <Container>
       {/* <Row>
          <Col xs="12" className="mb-5" style={{paddingTop:10}}>
            <div className="text-center">
              <Title>Independently Verified</Title>
            </div>
            <Text color="lightShade" fontSize="18px" lineHeight="28px">
              The paper describes the application of three different automated cell isolation and <br />
              dispensing devices that can enhance the single‐cell cloning process for hPSCs.<br /> 
              In combination with optimized cell culture conditions, these devices <br />
               offer an attractive alternative compared to manual methods. We explore <br />
               various aspects of each device system and define protocols for their practical application.
  </Text>
          </Col>
          
        </Row>*/}
          <Row className="justify-content-center mt-3">
            <Col lg="11">
              
                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  
                  <ContentCard
                    name="Methods for Automated Single Cell Isolation and Sub‐Cloning of Human Pluripotent Stem Cells"
                    author="Sebastian Diecke (Max Delbrück Center) &amp; Harald Stachelscheid (Charité—Universitätsmedizin) et al."
                    company="Current Protocols in Stem Cell Biology e123, Volume 55, 2020"
                    
                    image={imgOfferBook}
                  >
                    <img src={imgQuoteStart} alt="Quote_Icon" className="img-fluid" style={{margin: '-2px 6px 13px 3px',width:'20px',height:'14px'}} />
                    The isoCell platform is applied to enable robust, efficient, cost-effective, and easy-to-handle hPSC clone isolation and expansion.
                    <img src={imgQuoteEnd} alt="Quote_Icon" className="img-fluid" style={{margin: '-2px 3px 13px 6px',width:'20px',height:'14px'}}/>
                  </ContentCard>
                </Box>
                
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Quote;

import React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";

import { Title, Section, Button, Text } from "../../components/Core";

import isoCell from "../../assets/image/jpeg/isoCell.jpg";
import { bottom } from "styled-system";

const Table = styled.table`
z-index:999;
position:relative;
&:after {
  content: ""; 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter:blur(4px);
    -o-filter:blur(4px);
    -ms-filter:blur(4px);
    -moz-filter:blur(4px);
    -webkit-filter:blur(4px);
  background-color: #fff;
  opacity: .4;
}

thead,
  tr,
  tbody {
    display: block;
    filter:blur(1px);
    -o-filter:blur(1px);
    -ms-filter:blur(1px);
    -moz-filter:blur(1px);
    -webkit-filter:blur(1px);
    
  }
  thead tr .sin-title-box {
    padding: 0px 20px 5px 0px;
    height: 60px;
    color: #19191b;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -0.56px;
    filter:blur(1px);
    -o-filter:blur(1px);
    -ms-filter:blur(1px);
    -moz-filter:blur(1px);
    -webkit-filter:blur(1px);
  }
  .title-col {
    min-width: 290px;
  }
  .basic-col,
  .standard-col,
  .premium-col,
  .ent-col {
    min-width: 195px;
  }

  .double-col: {
    min-width: 390px;
  }
  .bad{
    background: #ffdde1;
  }
  .mid {
    background: #fff3c3;
  }
  .good {
    background: #dcf5e1;
  }
  .colored-row {
    background: #f7f7fb;
    border-top: 2px solid #fff;
    &:first-child {
      border-top: 0;
    }
    .sin-box.title-col {
      color: #19191b;
      font-weight: 700;
      min-width: 390px;
      max-width: 390px;
    }
    .sin-box {
      position: relative;
      padding: 0 20px;
      height: 65px;
      color: #696871;
      font-size: 18px;
      font-weight: 300;
      letter-spacing: -0.56px;
      .table-icon {
        font-size: 20px;
        &.neg {
          color: #f04037;
        }
        &.pos {
          color: #67d216;
        }
      }
      &:before {
        content: "";
        background: #fff;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        width: 1px;
      }
    }
  }


 
  `;

const ButtonSm = styled(Button)`
  width: 167px;
  min-width: auto;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #eae9f2;
  background-color: #ffffff;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.56px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  &:hover,
  &:focus {
    border: ${({ theme }) => `1px solid ${theme.colors.secondary}`} !important;
    background-color: ${({ theme }) => `${theme.colors.secondary}`} !important;
    color: #fff;
  }
`;

 const Overlay = styled.div`



  `;

const PricingTable2 = () => {
  return (
    <>

      <Section className="pt-0">
      <Overlay className="overlay">
        <Container>
          <div
            css={`
              overflow: auto;
              padding-bottom: 30px;
            `}
          >
            <Title>Preview:</Title>
            <Table className="overlay" css={`margin:auto`}>
              <thead>
                <tr>
                  <th scope="col" className="title-col sin-title-box" style={{minWidth:390, verticalAlign:"bottom"}} >
                    
                    Extract from Table 4
                  <Text variant="small" >
                  See the full <a href="" style={{textDecoration: "underline"}}>User Review Synopsis</a>
              </Text>
                  </th>
                  <th scope="col" style={{ verticalAlign: "bottom"}} className="basic-col sin-title-box pb-10">
                    isoCell
                  </th>
                  <th scope="col" style={{ verticalAlign: "bottom"}} className="standard-col sin-title-box pb-10">
                  Single-cell dispenser 1
                  </th>
                  <th scope="col" style={{ verticalAlign: "bottom"}} className="premium-col sin-title-box pb-10">
                  Single-cell dispenser 2
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="colored-row">
                  <th scope="row" className="title-col sin-box">
                    Training Time
                    
                  </th>
                  <td className="basic-col sin-box good">0.5 day</td>
                  <td className="standard-col sin-box bad">3-5 days</td>
                  <td className="premium-col sin-box mid">1 day</td>
                </tr>
                <tr className="colored-row">
                  <th scope="row" className="title-col sin-box">
                    Handling
                  </th>
                  <td className="basic-col sin-box good" >Easy
                  <Text style={{fontSize:"11px",lineHeight:"14px"}} >WDH 216 x 241 x 320 mm &amp; 4.3 kg</Text>
                  </td>
                  <td className="standard-col sin-box bad">Difficult</td>
                  <td className="premium-col sin-box mid">Moderate</td>
                </tr>
                <tr className="colored-row">
                  <th scope="row" className="title-col sin-box">
                    Equipment Maintenance
                  </th>
                  <td className="basic-col sin-box good">Low</td>
                  <td className="standard-col sin-box bad">Demanding</td>
                  <td className="premium-col sin-box good">Low</td>
                </tr>
                <tr className="colored-row">
                  <th scope="row" className="title-col sin-box">
                    Proof of Clonality
                  </th>
                  <td className="basic-col sin-box good">In-chamber</td>
                  <td className="standard-col sin-box bad">Before Chamber/<br/>Pre-deposition</td>
                  <td className="premium-col sin-box bad">Before Chamber<br/>Pre-deposition</td>
                  
                </tr>
                <tr className="colored-row">
                  <th scope="row" className="title-col sin-box">
                    Cell Culture Volume
                  </th>
                  <td className="basic-col sin-box good">&lt; 1 &micro;l</td>
                  <td className="standard-col sin-box bad">60 - 100 &micro;l</td>
                  <td className="premium-col sin-box bad">60 - 100 &micro;l</td>
                </tr>
                <tr className="colored-row">
                  <th scope="row" className="title-col sin-box">
                    Hardware Acquisition Costs
                  </th>
                  <td className="basic-col sin-box mid">Medium</td>
                  <td className="standard-col sin-box bad">High</td>
                  <td className="premium-col sin-box bad">High</td>
                </tr>
                <tr className="colored-row">
                  <th scope="row" className="title-col sin-box">
                    Consumable Cost
                  </th>
                  <td className="basic-col sin-box good">Low</td>
                  <td className="standard-col sin-box good">Low</td>
                  <td className="premium-col sin-box bad">High</td>
                </tr>
                {/*
                <tr className="colored-row">
                  <th scope="row" className="title-col sin-box">
                    Hardware Acquisition Cost
                  </th>
                  <td className="basic-col sin-box mid">Medium</td>
                  <td className="standard-col sin-box bad">High</td>
                  <td className="premium-col sin-box bad">High</td>
                </tr>
                <tr className="colored-row">
                  <th scope="row" className="title-col sin-box">
                    Consumables Cost
                  </th>
                  <td className="basic-col sin-box good">Low</td>
                  <td className="standard-col sin-box good">Low</td>
                  <td className="premium-col sin-box bad">High</td>
                </tr>
                <tr className="colored-row">
                  <th scope="row" className="title-col sin-box">
                    Deposition Volume
                  </th>
                  <td className="basic-col sin-box good">0.2-0.9&micro;l</td>
                  <td className="standard-col sin-box bad">60-100&micro;l</td>
                  <td className="premium-col sin-box bad">60-100&micro;l</td>
                </tr>
                <tr className="colored-row">
                  <th scope="row" className="title-col sin-box">
                    Embedding in Bigger Platform
                  </th>
                  <td className="basic-col sin-box bad">
                    <span className="table-icon neg">
                      <i className="fas fa-times"></i>
                    </span>
                  </td>
                  <td className="standard-col sin-box good">
                  <span className="table-icon pos">
                      <i className="fas fa-check"></i>
                    </span>
                  </td>
                  <td className="premium-col sin-box good">
                    <span className="table-icon pos">
                      <i className="fas fa-check"></i>
                    </span>
                  </td>
                  
                </tr>
                <tr className="colored-row">
                  <th scope="row" className="title-col sin-box">
                    Sample Recovery
                  </th>
                  <td className="basic-col sin-box bad">
                    <span className="table-icon neg">
                      <i className="fas fa-times"></i>
                    </span>
                  </td>
                  <td className="standard-col sin-box good">
                  <span className="table-icon pos">
                      <i className="fas fa-check"></i>
                    </span>
                  </td>
                  <td className="premium-col sin-box bad">
                  <span className="table-icon neg">
                      <i className="fas fa-times"></i>
                    </span>
                  </td>
                </tr>*/}
                

              </tbody>
            </Table>
          </div>
        </Container>
        </Overlay>
      </Section>
    </>
  );
};

export default PricingTable2;
